<template>
  <modal class="returnDishesModel" :isShow="isShow">
    <div class="header">退菜操作</div>
    <div class="modal-body">
        <input ref="moneyInput" type="hidden" v-model="returnMoney" data-model='returnMoney' />
        <div class="title">结账单号：{{info.Eat_CheckIndex}} &nbsp;&nbsp; 结算金额：{{info.Eat_CheckMoney}} &nbsp;&nbsp; 
            <el-tooltip class="item" effect="dark" :content="billInfo.Eat_DeskName" placement="bottom">
                <span class="inlineBlock nowrap">桌号：{{billInfo.Eat_DeskName}}</span>
            </el-tooltip>
        </div> 
        <div class="data-box">
            <div class="table-box">
                <el-table ref="tableEl" border :data="info?.Order_ConsumeList"  style="width: 100%;" height="268"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                    :summary-method="getSummaries"
                    show-summary
                >
                    <el-table-column prop="EAT_XFCode" label="菜品编码" min-width="90"></el-table-column>
                    <el-table-column prop="EAT_XFName" label="菜品名称" min-width="170"></el-table-column> 
                    <el-table-column prop="Eat_CheckPrice" label="单价" min-width="90"></el-table-column>
                    <el-table-column prop="Eat_Number" label="点单数量" min-width="80"> </el-table-column>
                    <el-table-column prop="Eat_XFSize" label="单位" min-width="80"></el-table-column>
                    <el-table-column prop="Refund_Number" label="已退菜数量" min-width="100"></el-table-column>
                    <el-table-column prop="Retrun_Number" label="退菜数量" min-width="100">
                        <template #default="scope">
                            <div class="input-from">
                                <input-pattern type="text" 
                                pattern="number"
                                :dplaces="3"
                                v-model="scope.row.Retrun_Number" 
                                @input="inputFocus" 
                                @focus="(e)=>{inputFocus(e,scope.row.Eat_Number-scope.row.Refund_Number);}" 
                                @blur="inputBlur"  />
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Retrun_Money" label="退菜金额" min-width="100">
                        <template #default="scope">
                            {{parseFloat((scope.row.Retrun_Number*scope.row.Eat_CheckPrice).toFixed(4))}}
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="row-box clearfix">
            <div class="col-box payment-box">
                <div class="title-lable">付款详情</div>
                <div class="table-box">
                    <el-table border :data="info?.Order_PaymentDetailList"  style="width: 100%;" height="180"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                        <el-table-column prop="Payment_Name" label="付款方式" min-width="80"></el-table-column>
                        <el-table-column prop="Payment_Money" label="原收款额" min-width="60"></el-table-column> 
                        <el-table-column prop="Refund_Money" label="已退款额" min-width="60"></el-table-column> 
                        <el-table-column prop="Return_Money" label="退款额" min-width="60">
                            <template #default="scope">
                                <div class="input-from">
                                    <input type="text" 
                                    v-input-pattern="'money'"
                                    v-model="scope.row.Return_Money" 
                                    @input="inputFocus" 
                                    @focus="(e)=>{ payInputFocus(e);}" 
                                    @blur="inputBlur"  />
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="col-box keyboard-box">
                <div class="title-lable"></div>
                <div class="keyboard">
                    <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                            data-value="9">9</span></div>
                    <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                            data-value="6">6</span></div>
                    <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                            data-value="3">3</span></div>
                    <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                            data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                </div>
            </div>
            <div class="col-box select-reason-box">
                <div class="title-lable">请选择原因</div>
                <div class="reason-box" v-mouse-scroll>
                    <el-skeleton :rows="4" animated :loading="!reasonLoading">
                        <template #default>
                            <el-empty v-if="!reasonList" description="暂无数据"></el-empty>
                            <div v-else class="reason-tag nowrap" 
                            :class="{selected:reasonInfo?.RefundReason_AutoID==item.RefundReason_AutoID}" 
                            @click="selectReason(item)"
                            v-for="item in (reasonList||[])" :key="item">{{item.RefundReason_Name}}</div>
                        </template>
                    </el-skeleton>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-box">
        <button class="btn btn-cancel" @click="hide()">取消</button>
        <button class="btn btn-confirm" @click="confirm(true)">退菜并打印结账单</button>
        <button class="btn btn-confirm" @click="confirm()">确认退菜</button>
    </div>
  </modal>
</template>

<script>
import inputPattern from '../../../../template/inputPattern/inputPattern.vue';
import {deepClone,initKeyBoard} from '/src/common/index.js';

export default {
  components: { inputPattern },
    name:'returnDishesModel',
    props:{
        isShow:Boolean,
        /**传入账单数据 */
        billInfo:Object
    },
    data(){
        return{
            payList:[],
            /**账单数据 */
            info:{},
            /**退菜原因数据 */
            reasonList:[],
            /**退菜原因数据 加载 */
            reasonLoading:false,
            /**选中 退菜原因 */
            reasonInfo:undefined,
            /**输入的退款金额 */
            returnMoney:0,
            /**要编辑的 input */
            editInput:undefined
        }
    },
    mounted(){
        this.$nextTick(()=>{
            initKeyBoard.call(this,this.$el,this.$refs.moneyInput);
            this.initData();
        })
    },
    watch:{
        isShow(value){
            if(value==true){
                this.initData();
            }
        },
        returnMoney(value){
            if(this.editInput && this.editInput.value!=value){
                this.diskey(this.editInput);
                this.editInput.value=value;
                this.editInput.dispatchEvent(new InputEvent("input",{data:value,inputType:'insertText'}));
            }
        }
    },
    computed:{
        total(){
            let money=0,num=0;
            (this.info?.Order_ConsumeList||[]).forEach((item)=>{
                if(item.Retrun_Number>0){
                    num +=parseFloat(item.Retrun_Number||0);
                    money += item.Retrun_Number*item.Eat_CheckPrice;
                }
            })
            return {
                Retrun_Number:num,
                Retrun_Money:money
            }
        }
    },
    methods:{
        /**初始化数据 */
        initData(){
            /**初始化数据 */
            this.reasonInfo=undefined
            this.info=deepClone(this.billInfo);
            this.info?.Order_ConsumeList?.forEach((item)=>{
                item.Retrun_Number=0;
            })
            this.info?.Order_PaymentDetailList?.forEach((item)=>{
                item.Return_Money=0;
            })

            if(!this.payTypeList || this.payTypeList.length==0){
                this.loadData();
            }
        },
        /**加载 退菜原因 */
        loadData(){
            this.reasonLoading=false;
            this.$cacheData.RefundReasons().then((d)=>{
                this.reasonLoading=true;
                if(d){
                    this.reasonList=d||[];
                }
            }).catch((e)=>{
                this.reasonLoading=true;
                this.$message.error('加载退菜原因数据失败：'+e,);
                console.log('加载退菜原因数据失败：'+e);
            })
        },
        selectReason(item){
            this.reasonInfo=item;
        },
        //点击退款额默认为退菜金额 如果是一个付款方式，自动填充退款额=退菜金额
        payInputFocus(e){
            if(this.info?.Order_PaymentDetailList?.length==1){
                this.inputFocus(e,this.total.Retrun_Money);
            }else{
                this.inputFocus(e);
            }
        },
        /** 获取焦点时 */
        inputFocus(e,defaultVal){
            if(e.target!=this.editInput){
                this.editInput=e.target;
            }
            if(isNaN(e.target.value)){
                return false;
            }
            if(e.type=="focus" && e.target.value==0){
                this.returnMoney=defaultVal>0?defaultVal:"";
            }else{
                this.returnMoney=e.target.value;
            }
            
        },
         /**失去焦点时 */
        inputBlur(e){
            if(e.target==this.editInput){
                this.editInput=undefined;
                if(e.target.value==""){
                    e.target.value=0;
                }
            }
        },
        //禁止安卓弹层软键盘
        diskey(input){
            if(input){
                let isReadonly=input.getAttribute("readonly");
                if(!isReadonly){
                    input.setAttribute("readonly","readonly");
                    if(input._setTime) clearTimeout(input._setTime);
                    input._setTime=setTimeout(()=>{
                        input?.removeAttribute("readonly");
                    },100)
                }
            }
        },
        hide(){
            this.$emit("closeModel");
        },
        confirm(isPrint){
            if(!this.reasonInfo?.RefundReason_Name){
                this.$message.warning('请先选择退菜原因!');
                return;
            }
            let returnMoney=0;//退菜金额
            let returnNum=0;//退菜数量
            let RefundOrderConsumes=[];//退单菜品
            for(let i=0;i<this.info?.Order_ConsumeList?.length;i++){
                let item=this.info?.Order_ConsumeList[i];
                if(item.Retrun_Number>0){
                    if(parseFloat(item.Retrun_Number)>item.Eat_Number){
                        this.$message.warning('菜品:'+item.EAT_XFName+' 退菜数量不能大于点菜数量！');
                        return ;
                    }

                    let money=item.Retrun_Number*item.Eat_CheckPrice;
                    returnMoney += money
                    returnNum += parseFloat(item.Retrun_Number||0);
               
                    RefundOrderConsumes.push({
                        Eat_ConsumeID:item.Eat_ConsumeID,
                        Refund_Number:item.Retrun_Number,
                        Refund_CheckPrice:item.Eat_CheckPrice,
                        Refund_CheckMoney:money
                    });
                }
                
            }
            returnNum=parseFloat(returnNum.toFixed(2));
            if(returnNum==0){
                this.$message.warning('退菜数量小计必须大于0！');
                return ;
            }

            let paymentReturnMoney=0;//退款金额
            let RefundOrderPayments=[];//退单支付金额
            for(let i=0;i<this.info?.Order_PaymentDetailList?.length;i++){
                let item=this.info?.Order_PaymentDetailList[i];
                if(item.Return_Money>0){
                    if(parseFloat(item.Return_Money) >item.Payment_Money){
                        this.$message.warning('退款方式:'+item.Payment_Name+' 退款金额不能大于原收款额！');
                        return ;
                    }
                    paymentReturnMoney += parseFloat(item.Return_Money);

                    RefundOrderPayments.push({
                        Eat_PaymentDetailID:item.Eat_PayDetailID,
                        Refund_Money:item.Return_Money
                    });
                }
            }
            returnMoney=parseFloat(parseFloat(returnMoney).toFixed(2));
            paymentReturnMoney=parseFloat(parseFloat(paymentReturnMoney).toFixed(2));

            if(returnMoney!=paymentReturnMoney){
                this.$message.warning('退菜金额与退款金额不一致！');
                return;
            }
            this.$emit("confirm",{
                Refund_TotalMoney:returnMoney,
                Refund_Reason:this.reasonInfo?.RefundReason_Name,
                RefundOrderConsumes:RefundOrderConsumes,
                RefundOrderPayments:RefundOrderPayments,
                Print_YN:isPrint
            });
        },
        /**合计 */
        getSummaries({columns}){
            const sums = [];
            sums[1] = '退菜小计';

            columns.forEach((column, index) => {
                if(column.property=="Retrun_Number"){
                    sums[index] = parseFloat(this.total.Retrun_Number.toFixed(2));
                }else if(column.property=="Retrun_Money"){
                    sums[index] = parseFloat(this.total.Retrun_Money.toFixed(2));
                }
            })
            return sums;
        }
    }
}
</script>

<style lang="scss">
   @import "./returnDishesModel.scss"
</style>